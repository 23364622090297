.App-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Separator {
  text-align: center;
  margin-bottom: 0px;
}

.Separator-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100px 0 100px;
  border-color: #333 transparent transparent transparent;
  display: inline-block;
}
