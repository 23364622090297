.ProjectList-button {
  color: rgb(255, 162, 86) !important;
  border-bottom: 1px solid rgba(255, 162, 86, 0) !important;
  padding: 8px 32px !important;
  background-color: #333 !important;
}

.button-github {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
